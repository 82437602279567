import Vue from "vue";
import VueRouter from "vue-router";
import { permissions } from "@/enums/permissions";
import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Paper/Dashboard"),
    redirect: "/dashboard",
    children: [
      {
        path: "/boutique",
        name: "boutique",
        meta: {
          guard: true,
          title: () => {
            return $gettext("Store");
          },
          showStripeIntervalBanner: true
        },
        component: () => import("@/views/Boutique/ProductList")
      },
      {
        path: "/subscription",
        name: "subscription",
        meta: {
          guard: true,
          permission: permissions.ManageSubscriptions,
          title: () => {
            return $gettext("Subscription");
          }
        },
        component: () => import("@/views/Subscription")
      },
      {
        path: "/credit-cards",
        name: "credit-cards",
        meta: {
          guard: true,
          permission: permissions.ManageCreditCards,
          title: () => {
            return $gettext("Credit cards");
          }
        },
        component: () => import("@/views/CreditCards")
      },
      {
        path: "/billing-history",
        name: "billing-history",
        meta: {
          guard: true,
          permission: permissions.ManageSubscriptions,
          title: () => {
            return $gettext("Billing history");
          }
        },
        component: () => import("@/views/Billing")
      },
      {
        path: "/cart",
        name: "cart",
        meta: {
          guard: true,
          fetching: true,
          permission: permissions.ManageSubscriptions,
          title: () => {
            return $gettext("My Cart");
          },
          showStripeIntervalBanner: true
        },
        component: () => import("@/views/Boutique/Cart")
      },
      {
        path: "/checkout",
        name: "checkout",
        meta: {
          guard: true,
          fetching: true,
          permission: permissions.ManageSubscriptions,
          title: () => {
            return $gettext("Checkout");
          }
        },
        component: () => import("@/views/Boutique/Checkout")
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          guard: true,
          title: () => {
            return $gettext("Dashboard");
          }
        },
        component: () => import("@/views/Dashboard")
      },
      {
        path: "/offers",
        name: "offers",
        meta: {
          guard: true,
          title: () => {
            return $gettext("Job Offers");
          }
        },
        component: () => import("@/views/Offers/OfferList")
      },
      {
        path: "/create-offer",
        name: "create-offer",
        meta: {
          guard: true,
          permission: permissions.ManageOffers,
          fetching: false,
          title: () => {
            return $gettext("Create offer");
          }
        },
        component: () => import("@/views/Offers/OfferForm")
      },
      {
        path: "/create-credit-card",
        name: "create-credit-card",
        meta: {
          guard: true,
          permission: permissions.ManageCreditCards,
          fetching: false,
          title: () => {
            return $gettext("Add credit card");
          }
        },
        component: () => import("@/views/Boutique/CreateCreditCard")
      },
      {
        path: "/update-offer/:offerId",
        name: "update-offer",
        meta: {
          guard: true,
          permission: permissions.ManageOffers,
          title: () => {
            return $gettext("Update offer");
          }
        },
        props: true,
        component: () => import("@/views/Offers/OfferForm")
      },
      {
        path: "/offers/:offerId",
        props: true,
        meta: {
          guard: true,
          fetching: false,
          title: () => {
            return $gettext("Offer");
          }
        },
        component: () => import("@/views/Offers/Offer"),
        children: [
          {
            meta: {
              guard: true,
              fetching: false,
              title: () => {
                return $gettext("Offer - Details");
              }
            },
            path: "details",
            name: "offer-details",
            component: () => import("@/views/Offers/Tabs/Details")
          },
          {
            path: "applications",
            name: "offer-applications",
            meta: {
              guard: true,
              fetching: false,
              title: () => {
                return $gettext("Offer - Applications");
              }
            },
            props: true,
            component: () => import("@/views/Offers/Tabs/Applications"),
            children: [
              {
                meta: {
                  guard: true,
                  fetching: false,
                  title: () => {
                    return $gettext("Offer - Applications");
                  }
                },
                name: "application-candidate",
                path: ":applicationId?",
                component: () => import("@/views/Offers/Tabs/Candidate"),
                props: true
              }
            ]
          },
          {
            meta: {
              guard: true,
              fetching: false,
              title: () => {
                return $gettext("Offer - Posting platforms");
              }
            },
            path: "publications",
            name: "publications",
            component: () => import("@/views/Offers/Tabs/Publications")
          }
        ]
      },
      {
        path: "/candidates",
        name: "candidates",
        meta: {
          guard: true,
          title: () => {
            return $gettext("Candidates");
          }
        },
        component: () => import("@/views/Candidates/CandidateList")
      },
      {
        path: "/applications",
        name: "applications",
        meta: {
          guard: true,
          title: () => {
            return $gettext("Applications");
          }
        },
        component: () => import("@/views/Applications/ApplicationList")
      },
      {
        path: "/create-candidate",
        name: "create-candidate",
        meta: {
          guard: true,
          fetching: false,
          permission: permissions.ManageCandidates,
          title: () => {
            return $gettext("Create candidate");
          }
        },
        component: () => import("@/views/Candidates/CreateCandidate")
      },
      {
        path: "/candidates/:candidateId",
        name: "candidate-details",
        props: true,
        meta: {
          guard: true,
          title: () => {
            return $gettext("Candidate");
          }
        },
        component: () => import("@/views/Candidates/Candidate")
      },
      {
        path: "/companies",
        name: "companies",
        meta: {
          guard: true,
          title: () => {
            return $gettext("Companies");
          }
        },
        component: () => import("@/views/Companies/CompanyList")
      },
      {
        path: "/users",
        name: "users",
        meta: {
          guard: true,
          permission: permissions.ManageUsers,
          title: () => {
            return $gettext("Users");
          }
        },
        component: () => import("@/views/Users/UserList")
      },
      {
        path: "/users/:id/update",
        name: "update-user",
        props: true,
        meta: {
          guard: true,
          permission: permissions.ManageUsers,
          title: () => {
            return $gettext("User");
          }
        },
        component: () => import("@/views/Users/UpdateUser")
      },
      {
        path: "/users/invite",
        name: "invite-user",
        meta: {
          guard: true,
          permission: permissions.ManageUsers,
          fetching: false,
          title: () => {
            return $gettext("Invite user");
          }
        },
        component: () => import("@/views/Users/InviteUser")
      },
      {
        path: "/account",
        name: "account",
        meta: {
          guard: true,
          fetching: false,
          title: () => {
            return $gettext("My profile");
          }
        },
        component: () => import("@/views/Account")
      },
      {
        path: "/saved-filters-form/:id?",
        name: "saved-filters-form",
        props: true,
        meta: {
          guard: true,
          fetching: true,
          title: () => {
            return $gettext("Saved Filters");
          }
        },
        component: () => import("@/views/SavedFilters/SavedFilterForm.vue")
      },
      {
        path: "/saved-filters",
        name: "saved-filters",
        props: true,
        meta: {
          guard: true,
          fetching: true,
          title: () => {
            return $gettext("Saved Filters");
          }
        },
        component: () => import("@/views/SavedFilters")
      },
      {
        path: "/teams",
        name: "teams",
        meta: {
          guard: true,
          fetching: false,
          title: () => {
            return $gettext("My Teams");
          }
        },
        component: () => import("@/views/Teams")
      },
      {
        path: "/companies/:companyId",
        name: "company-details",
        props: true,
        meta: {
          guard: true,
          title: () => {
            return $gettext("Company");
          }
        },
        component: () => import("@/views/Companies/Company")
      },
      {
        path: "/create-company",
        name: "create-company",
        meta: {
          guard: true,
          fetching: false,
          permission: permissions.ManageCompanies,
          title: () => {
            return $gettext("Create a new company");
          }
        },
        component: () => import("@/views/Companies/CreateCompany")
      },
      {
        path: "/create-team",
        name: "create-team",
        meta: {
          fetching: false,
          guard: true,
          title: () => {
            return $gettext("Create a team");
          }
        },
        component: () => import("@/views/CreateTeam")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/layouts/Paper/FullPage"),
    children: [
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/ForgotPasswordForm"),
        meta: {
          title: () => {
            return $gettext("Forgot password");
          }
        }
      },
      {
        path: "/reset-password/:selector/:token",
        name: "reset-password",
        props: true,
        component: () => import("@/views/ResetPassword"),
        meta: {
          title: () => {
            return $gettext("Reset password");
          }
        }
      },
      {
        path: "/accept-invitation/:selector/:token",
        name: "accept-invitation",
        props: true,
        component: () => import("@/views/AcceptInvitation"),
        meta: {
          title: () => {
            return $gettext("Choose your password");
          }
        }
      },
      {
        path: "/invalid-link/:selector/:token",
        name: "invalid-link",
        props: true,
        component: () => import("@/views/InvalidLink"),
        meta: {
          title: () => {
            return $gettext("Invalid link");
          }
        }
      },
      {
        path: "/email-confirmation/:selector/:token",
        name: "email-confirmation",
        props: true,
        component: () => import("@/views/EmailConfirmation"),
        meta: {
          title: () => {
            return $gettext("Email verified");
          }
        }
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/layouts/Paper/CoverPage"),
    children: [
      {
        path: "/login",
        alias: ["/fr/connexion", "/en/login"],
        name: "login",
        component: () => import("@/views/Login"),
        meta: {
          title: () => {
            return $gettext("Login");
          }
        }
      },
      {
        path: "/signup",
        name: "signup",
        alias: ["/fr/inscription/", "/en/register"],
        component: () => import("@/views/SignUp"),
        meta: {
          title: () => {
            return $gettext("Register");
          }
        }
      }
    ]
  },
  {
    path: "/sso/:selector/:token",
    name: "sso",
    props: true,
    component: () => import("@/views/SsoLanding"),
    meta: {
      title: () => {
        return $gettext("Authentification");
      }
    }
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
